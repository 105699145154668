import axios from "@/plugins/axios";
import authHeader from '../auth-header'
import ConfigService from "../config"


const API_URL = ConfigService.base_url;

class SupportServiceApi {
    getSupportDetails(params) {
        return axios
          .get(API_URL +'admin/support',
          {
              params: params,
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
      updateSupportDetails(data) {
        return axios
          .post(API_URL +'admin/support',data,
          {
              headers:authHeader()
          })
          .then(response => {
            return response;
          })
          .catch(error=> {
              return Promise.reject(error);
            }
          )
      }
  
  
}



export default new SupportServiceApi()