<script>
import Layout from "../../layouts/main";
import Support from "@/components/support";
import SupportServiceApi from "@/service/api/support";
import Spinner from "@/components/spinner";

export default {
  components: { Layout, Support, Spinner },
  data() {
    return {
      deliveryData: [],
      returnRefundData: [],
      orderIssueData: [],
      technicalData: [],
      productData: [],
      status: {
        delivery: {
          priority: null,
          complete: 0,
        },
        returnRefund: {
          priority: null,
          complete: 0,
        },
        orderIssue: {
          priority: null,
          complete: 0,
        },
        technical: {
          priority: null,
          complete: 0,
        },
        product: {
          priority: null,
          complete: 0,
        },
      },
      page: {
        delivery: {
          rows: null,
          perPage: 10,
          currentPage: 1,
        },
        returnRefund: {
          rows: null,
          perPage: 10,
          currentPage: 1,
        },
        orderIssue: {
          rows: null,
          perPage: 10,
          currentPage: 1,
        },
        technical: {
          rows: null,
          perPage: 10,
          currentPage: 1,
        },
        product: {
          rows: null,
          perPage: 10,
          currentPage: 1,
        },
      },
      loading: true,
    };
  },
  computed: {},
  mounted() {
    this.loadData({
      department: "Delivery",
    });
  },

  methods: {
    async loadData(params) {
      this.loading = true;
      switch (params.department) {
        case "Delivery":
          params.priority = this.status.delivery.priority;
          params.complete = this.status.delivery.complete;
          params.paginate = this.page.delivery.perPage;
          params.page = this.page.delivery.currentPage;
          await SupportServiceApi.getSupportDetails(params)
            .then((response) => {
              if (response.data.success) {
                this.deliveryData = response.data.support.data;
                this.page.delivery.rows = response.data.support.total;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          break;
        case "Return & Refunds":
          params.priority = this.status.returnRefund.priority;
          params.complete = this.status.returnRefund.complete;
          params.paginate = this.page.returnRefund.perPage;
          params.page = this.page.returnRefund.currentPage;
          await SupportServiceApi.getSupportDetails(params)
            .then((response) => {
              if (response.data.success) {
                this.returnRefundData = response.data.support.data;
                this.page.returnRefund.rows = response.data.support.total;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          break;
        case "Order Issues":
          params.priority = this.status.orderIssue.priority;
          params.complete = this.status.orderIssue.complete;
          params.paginate = this.page.orderIssue.perPage;
          params.page = this.page.orderIssue.currentPage;
          await SupportServiceApi.getSupportDetails(params)
            .then((response) => {
              if (response.data.success) {
                this.orderIssueData = response.data.support.data;
                this.page.orderIssue.rows = response.data.support.total;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          break;
        case "Technical":
          params.priority = this.status.technical.priority;
          params.complete = this.status.technical.complete;
          params.paginate = this.page.technical.perPage;
          params.page = this.page.technical.currentPage;
          await SupportServiceApi.getSupportDetails(params)
            .then((response) => {
              if (response.data.success) {
                this.technicalData = response.data.support.data;
                this.page.technical.rows = response.data.support.total;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          break;
        case "Products":
          params.priority = this.status.product.priority;
          params.complete = this.status.product.complete;
          params.paginate = this.page.product.perPage;
          params.page = this.page.product.currentPage;
          await SupportServiceApi.getSupportDetails(params)
            .then((response) => {
              if (response.data.success) {
                this.productData = response.data.support.data;
                this.page.product.rows = response.data.support.total;
              }
            })
            .finally(() => {
              this.loading = false;
            });

          break;
        default:
          break;
      }
    },
    supportTabChange(val) {
      switch (val) {
        case 0:
          this.loadData({
            department: "Delivery",
          });
          break;
        case 1:
          this.loadData({
            department: "Return & Refunds",
          });
          break;
        case 2:
          this.loadData({
            department: "Order Issues",
          });
          break;
        case 3:
          this.loadData({
            department: "Technical",
          });
          break;
        case 4:
          this.loadData({
            department: "Products",
          });
          break;
        default:
          break;
      }
    },
    toggleCompleteStatus(val) {
      this.updatePriority(
        {
          id: val.id,
          complete: val.status ? "1" : "0",
          action: "update",
        },
        val.dept
      );
    },
    changeProgress(val) {
      this.updatePriority(
        {
          id: val.id,
          priority: val.priority,
          action: "update",
        },
        val.dept
      );
    },
    searchActive(params) {
      switch (params.department) {
        case "Delivery":
          this.page.delivery.currentPage = params.page;
          this.loadData(params);
          break;
        case "Return & Refunds":
          this.page.returnRefund.currentPage = params.page;
          this.loadData(params);
          break;
        case "Order Issues":
          this.page.orderIssue.currentPage = params.page;
          this.loadData(params);
          break;
        case "Technical":
          this.page.technical.currentPage = params.page;
          this.loadData(params);
          break;
        case "Products":
          this.page.product.currentPage = params.page;
          this.loadData(params);
          break;
        default:
          break;
      }
    },
    async updatePriority(data, dept) {
      await SupportServiceApi.updateSupportDetails(data).then((response) => {
        if (response.data.success) {
          this.loadData({ department: dept });
        }
      });
    },
    filterActiveChange(val) {
      switch (val.department) {
        case "Delivery":
          this.status.delivery.complete = val.complete;
          this.page.delivery.currentPage = 1;
          this.loadData(val);
          break;
        case "Return & Refunds":
          this.status.returnRefund.complete = val.complete;
          this.page.returnRefund.currentPage = 1;
          this.loadData(val);

          break;
        case "Order Issues":
          this.status.orderIssue.complete = val.complete;
          this.page.orderIssue.currentPage = 1;
          this.loadData(val);

          break;
        case "Technical":
          this.status.technical.complete = val.complete;
          this.page.technical.currentPage = 1;
          this.loadData(val);

          break;
        case "Products":
          this.status.product.complete = val.complete;
          this.page.product.currentPage = 1;
          this.loadData(val);

          break;
        default:
          break;
      }
    },
    priorityActiveChange(val) {
      switch (val.department) {
        case "Delivery":
          this.status.delivery.priority = val.priority;
          this.page.delivery.currentPage = 1;
          this.loadData(val);
          break;
        case "Return & Refunds":
          this.status.returnRefund.priority = val.priority;
          this.page.returnRefund.currentPage = 1;
          this.loadData(val);
          break;
        case "Order Issues":
          this.status.orderIssue.priority = val.priority;
          this.page.orderIssue.currentPage = 1;
          this.loadData(val);
          break;
        case "Technical":
          this.status.technical.priority = val.priority;
          this.page.technical.currentPage = 1;
          this.loadData(val);
          break;
        case "Products":
          this.status.product.priority = val.priority;
          this.page.product.currentPage = 1;
          this.loadData(val);
          break;
        default:
          break;
      }
    },
    pageChanged(params) {
      switch (params.department) {
        case "Delivery":
          this.page.delivery.currentPage = params.page;
          this.loadData(params);
          break;
        case "Return & Refunds":
          this.page.returnRefund.currentPage = params.page;
          this.loadData(params);
          break;
        case "Order Issues":
          this.page.orderIssue.currentPage = params.page;
          this.loadData(params);
          break;
        case "Technical":
          this.page.technical.currentPage = params.page;
          this.loadData(params);
          break;
        case "Products":
          this.page.product.currentPage = params.page;
          this.loadData(params);
          break;
        default:
          break;
      }
    },
  },
  watch: {},
};
</script>

<template>
  <Layout>
    <div class="spinner-pos">
      <Spinner :show="loading" />
    </div>
    <b-tabs @activate-tab="supportTabChange">
      <b-tab :title="$t('page.support.tab.delivery')">
        <div class="bg-white">
          <Support
            v-on:completeToggle="toggleCompleteStatus"
            :data="deliveryData"
            v-on:changeProgress="changeProgress"
            v-on:searchTerm="searchActive"
            v-on:filterActive="filterActiveChange"
            v-on:priorityActive="priorityActiveChange"
            v-on:currentPageChange="pageChanged"
            type="Delivery"
            :rows="page.delivery.rows"
            :perPage="page.delivery.perPage"
            :currentPage="page.delivery.currentPage"
          />
        </div>
      </b-tab>
      <b-tab :title="$t('page.support.tab.returnAndRefunds')">
        <div class="bg-white">
          <Support
            v-on:completeToggle="toggleCompleteStatus"
            :data="returnRefundData"
            v-on:changeProgress="changeProgress"
            v-on:searchTerm="searchActive"
            v-on:filterActive="filterActiveChange"
            v-on:priorityActive="priorityActiveChange"
            v-on:currentPageChange="pageChanged"
            type="Return & Refunds"
            :rows="page.returnRefund.rows"
            :perPage="page.returnRefund.perPage"
            :currentPage="page.returnRefund.currentPage"
          />
        </div>
      </b-tab>
      <b-tab :title="$t('page.support.tab.orderIssues')">
        <div class="bg-white">
          <Support
            v-on:completeToggle="toggleCompleteStatus"
            :data="orderIssueData"
            v-on:changeProgress="changeProgress"
            v-on:searchTerm="searchActive"
            v-on:filterActive="filterActiveChange"
            v-on:priorityActive="priorityActiveChange"
            v-on:currentPageChange="pageChanged"
            type="Order Issues"
            :rows="page.orderIssue.rows"
            :perPage="page.orderIssue.perPage"
            :currentPage="page.orderIssue.currentPage"
          /></div
      ></b-tab>
      <b-tab :title="$t('page.support.tab.technical')">
        <div class="bg-white">
          <Support
            v-on:completeToggle="toggleCompleteStatus"
            :data="technicalData"
            v-on:changeProgress="changeProgress"
            v-on:searchTerm="searchActive"
            v-on:filterActive="filterActiveChange"
            v-on:priorityActive="priorityActiveChange"
            v-on:currentPageChange="pageChanged"
            type="Technical"
            :rows="page.technical.rows"
            :perPage="page.technical.perPage"
            :currentPage="page.technical.currentPage"
          /></div
      ></b-tab>
      <b-tab :title="$t('page.support.tab.products')"
        ><div class="bg-white">
          <Support
            v-on:completeToggle="toggleCompleteStatus"
            v-on:changeProgress="changeProgress"
            v-on:searchTerm="searchActive"
            :data="productData"
            v-on:filterActive="filterActiveChange"
            v-on:priorityActive="priorityActiveChange"
            v-on:currentPageChange="pageChanged"
            type="Products"
            :rows="page.product.rows"
            :perPage="page.product.perPage"
            :currentPage="page.product.currentPage"
          />
        </div>
      </b-tab>
    </b-tabs>
  </Layout>
</template>
