<script>
export default {
  props: ["data", "type", "rows", "perPage", "currentPage"],
  data() {
    return {
      fields: [
        { key: "id", sortable: false, label: "ID" },
        {
          key: "user_email",
          sortable: false,
          label: this.$t("page.support.table.email"),
        },
        {
          key: "message",
          sortable: false,
          label: this.$t("page.support.table.message"),
        },
        {
          key: "complete",
          sortable: false,
          label: this.$t("page.support.table.completed"),
        },
        {
          key: "change_priority",
          sortable: false,
          label: this.$t("page.support.table.changePriority"),
        },
      ],
      priorityOptions: [
        {
          text: "All",
          value: null,
          default: true,
        },
        {
          text: "Urgent",
          value: 3,
        },
        {
          text: "High",
          value: 2,
        },
        {
          text: "Moderate",
          value: 1,
        },
        {
          text: "Low",
          value: 0,
        },
      ],
      prioritySelect: null,
      searchTerm: null,
      isActive: false,
      currentPageSelect: this.currentPage,
    };
  },
  methods: {
    formatData(data) {
      if (data) {
        return data.map((item) => {
          return {
            id: item.id,
            user_email: item.user_email,
            message: item.message,
            priority: item.priority,
            complete: item.complete === "1" ? true : false,
            change_priority: item.change_priority,
            dept: item.dept,
          };
        });
      } else return [];
    },
    completeToggle(val, id, dept) {
      this.$emit("completeToggle", {
        id: id,
        status: val,
        dept: dept,
      });
    },
    changePriority(priority, id, dept) {
      this.$emit("changeProgress", {
        id: id,
        priority: priority,
        dept: dept,
      });
    },
    searchTermEmitter() {
      this.$emit("searchTerm", {
        search: this.searchTerm,
        department: this.type,
      });
    },
  },
  watch: {
    isActive(val) {
      this.$emit("filterActive", {
        complete: val ? "1" : "0",
        department: this.type,
      });
    },
    prioritySelect(val) {
      this.$emit("priorityActive", {
        priority: val,
        department: this.type,
      });
    },
    currentPageSelect(val) {
      this.$emit("currentPageChange", {
        page: val,
        department: this.type,
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="row" style="padding-top:20px;padding-left:5px;">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            Priority&nbsp;
            <b-form-select
              v-model="prioritySelect"
              size="sm"
              :options="priorityOptions"
            ></b-form-select>
          </label>
          <label class="d-inline-flex align-items-center">
            Completed
            <span style="padding-left:5px;">
              <b-form-checkbox
                class="danger"
                v-model="isActive"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </span>
          </label>
        </div>
      </div>
      <!-- Search -->
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <span class="d-inline-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              type="search"
              placeholder="Search..."
              class="form-control form-control-sm ml-2"
            ></b-form-input>
            <button class="btn-primary ml-1" @click="searchTermEmitter">
              <i class="ri-search-line"></i>
            </button>
          </span>
        </div>
      </div>
    </div>

    <div class="table-responsive mb-0" v-if="data">
      <b-table :items="formatData(data)" :fields="fields" responsive="sm">
        <template v-slot:cell(user_email)="row">
          {{ row.value }}
          <div>
            <i
              class="ri-arrow-up-line text-danger"
              v-if="row.item.priority === '3'"
            >
              Urgent</i
            >
            <i
              class="ri-arrow-up-line text-warning"
              v-if="row.item.priority === '2'"
            >
              High</i
            >
            <i
              class="ri-arrow-down-line text-info"
              v-if="row.item.priority === '1'"
            >
              Moderate</i
            >
            <i
              class="ri-arrow-down-line text-primary"
              v-if="row.item.priority === '0'"
              >Low
            </i>
          </div>
        </template>
        <template v-slot:cell(message)="row">
          <div style="min-width:300px">
            {{ row.value }}
          </div>
        </template>
        <template v-slot:cell(complete)="row">
          <b-form-checkbox
            v-model="row.item.complete"
            class="danger"
            name="check-button"
            switch
            @change="completeToggle(row.value, row.item.id, row.item.dept)"
          >
          </b-form-checkbox>
        </template>
        <template v-slot:cell(change_priority)="row">
          <div style=" width: 148px;">
            <button
              class="priority-btn"
              title="Urgent"
              @click="changePriority('3', row.item.id, row.item.dept)"
            >
              <i class="ri-arrow-up-line text-danger"> </i>
            </button>
            <button
              class="priority-btn"
              title="High"
              @click="changePriority('2', row.item.id, row.item.dept)"
            >
              <i class="ri-arrow-up-line text-warning"> </i>
            </button>
            <button
              class="priority-btn"
              title="Moderate"
              @click="changePriority('1', row.item.id, row.item.dept)"
            >
              <i class="ri-arrow-down-line text-info"> </i>
            </button>
            <button
              class="priority-btn"
              title="Low"
              @click="changePriority('0', row.item.id, row.item.dept)"
            >
              <i class="ri-arrow-down-line text-primary"></i>
            </button>
          </div>
        </template>
      </b-table>
    </div>
    <div class="no-message" v-if="data && !data.length">
      No Messages!
    </div>
    <div class="row" v-else style="padding-bottom:50px;">
      <div class="col">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination pagination-rounded mb-0 float-right">
            <!-- pagination -->
            <b-pagination
              v-model="currentPageSelect"
              :total-rows="rows"
              :per-page="perPage"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.priority-btn {
  background-color: lavenderblush;
  display: inline-block;
  border: none;
  margin: 5px;
  border-radius: 30px;
}

.priority-btn:hover {
  background-color: antiquewhite;
}
.priority-st i {
  background-color: #f1f1f1;
  border-radius: 30px;
  padding: 5px 5px;
}
.no-message {
  text-align: center;
  font-size: 18px;
  padding: 100px 0;
}
</style>
